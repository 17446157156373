<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>电影票</el-breadcrumb-item>
      <el-breadcrumb-item>电影票订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入订单号进行搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
            @clear="getGoodList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getGoodList()"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <!-- table 表格区域 -->
        <el-button
          type="primary"
          size="mini"
          icon="fa fa-check"
          plain
          @click="setNum"
          >&nbsp;设置电影票价格</el-button
        >
      </el-row>
      <!-- table 表格区域 -->
      <el-table
        :data="goodsList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNumber"
          width="150px"
        ></el-table-column>
        <el-table-column label="电影名字" prop="movieName" width="200px">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.movieName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="movieStart" width="90px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.movieStart
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="二维码" prop="avatar" width="66px">
          <template slot-scope="scope">
            <el-avatar
              :size="'samll'"
              shape="square"
              :src="scope.row.movieQr"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column label="取票码" prop="movieCode" width="130px">
          <template slot-scope="scope">
            {{ scope.row.movieCode }}
          </template>
        </el-table-column>
        <el-table-column label="电影日期" prop="movieCode" width="130px">
          <template slot-scope="scope">
            {{ scope.row.movieDate }}
          </template>
        </el-table-column>
        <el-table-column width="80px" label="单价" prop="moviePrice">
          <template slot-scope="scope">
            <!-- <el-tag type="warning" size="medium">{{
              scope.row.ticketSum
            }}</el-tag> -->
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.moviePrice"
              class="item"
              type="primary"
            >
            </el-badge>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="购买数量" prop="movieNum">
          <template slot-scope="scope">
            <!-- <el-tag type="warning" size="medium">{{
              scope.row.ticketSum
            }}</el-tag> -->
            <el-badge
              style="margin-top:1em;"
              :value="scope.row.movieNum"
              class="item"
              type="primary"
            >
            </el-badge>
          </template>
        </el-table-column>
        <el-table-column label="描述" prop="movieDesc" width="240px">
          <template slot-scope="scope">
            {{ scope.row.movieDesc }}
          </template>
        </el-table-column>
        <el-table-column label="其他信息" prop="others" width="240px">
          <template slot-scope="scope">
            {{ scope.row.others }}
          </template>
        </el-table-column>
        <el-table-column
          label="是否付款"
          width="90px"
          prop="payStatus"
          :filter-method="filterTag"
          :filters="[
            { text: '已付款', value: '1' },
            { text: '未付款', value: '0' },
            { text: '已退款', value: '4' },
            { text: '退款中', value: '2' }
          ]"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.payStatus == '1'"
              >已付款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '2'"
              >退款中</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '3'"
              >未退款</el-tag
            >
            <el-tag type="primary" v-else-if="scope.row.payStatus == '4'"
              >已退款</el-tag
            >
            <el-tag type="danger" v-else>未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="是否失效">
          <template slot-scope="scope">
            <!-- {{scope.row}} -->
            <el-switch
              v-model="scope.row.isExpire"
              @change="usersChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="90px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="fa fa-pencil"
              plain
              circle
              @click="showEditDialog(scope.row.movieId)"
            ></el-button>
            <el-button
              type="primary"
              size="mini"
              icon="fa fa-pencil"
              plain
              circle
              @click="sendSms()"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <a-modal
      v-drag-modal
      v-model="visible"
      title="编辑"
      @ok="handleOk"
      cancelText="取消"
      okText="保存"
    >
      <!-- 内容主体区域 -->
      <el-form :model="addForm" ref="addFormRef" label-width="70px">
        <el-form-item label="取票码" prop="code">
          <el-input v-model="addForm.code"></el-input>
        </el-form-item>
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="upload"
        >
          <img
            v-if="addForm.url"
            :src="addForm.url"
            class="avatar"
            style="margin-left: 70px;"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon">二维码</i>
        </el-upload>
      </el-form>
    </a-modal>

    <a-modal
      v-drag-modal
      v-model="visible1"
      title="发送短信"
      @ok="handleOk1"
      cancelText="取消"
      okText="立即发送"
    >
      <!-- 内容主体区域 -->
      <el-form :model="addForm" ref="addFormRef" label-width="70px">
        <el-form-item label="取票码" prop="code">
          <el-input v-model="addForm1.code"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="addForm1.phone"></el-input>
        </el-form-item>
      </el-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'orderstar',
  data() {
    return {
      // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      goodsList: [],
      total: 0,
      visible: false,
      visible1: false,
      lists: [],
      addForm: {
        url: '',
        code: ''
      },
      addForm1: {
        code: '',
        phone: ''
      },
      id: 0
    }
  },
  methods: {
    sendSms() {
      this.visible1 = true
    },
    setNum(row) {
      this.$prompt('请输入金额', '设置弹窗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          const { data: res } = await this.$http.put(`/xkeys/movie`, {
            num: value
          })
          if (res.code !== '20000') {
            this.$message({
              type: 'error',
              message: res.message
            })
            return false
          } else {
            this.$message({
              type: 'success',
              message: res.message
            })
            return false
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '你取消了保存操作'
          })
        })
    },
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    upload(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      const url = 'https://api.shunhou.wandoukeji.com/api/v1/uploadImage'
      this.$http
        .post(url, formData)
        .then(res => {
          console.log(res)
          this.$layer.msg('上传图片成功')
          this.addForm.url = res.data.data.path
        })
        .catch(response => {
          this.$layer.msg('上传图片失败')
        })
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      // const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isJPG || !isPNG) {
      //   this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    showEditDialog(id) {
      console.log(id)
      this.visible = true
      this.id = id
    },
    async handleOk1() {
      // eslint-disable-next-line eqeqeq
      if (this.addForm1.code == '' || this.addForm1.phone == '') {
        this.$message({
          showClose: true,
          message: '参数不能为空',
          type: 'error'
        })
        return
      }
      const res = await this.$http.put(`movies/send/${this.addForm1.code}/${this.addForm1.phone}`)
      if (res.data.code !== '20000') {
        this.$message({
          showClose: true,
          message: res.data.message,
          type: 'error'
        })
        return true
      } else {
        this.addForm1 = {}
        this.visible1 = false
        this.$message({
          showClose: true,
          message: res.data.message,
          type: 'success'
        })
      }
    },
    async handleOk() {
      // console.log(e)
      // eslint-disable-next-line eqeqeq
      if (this.addForm.url == '' || this.addForm.code == '') {
        this.$message({
          showClose: true,
          message: '参数不能为空',
          type: 'error'
        })
        return
      }
      const res = await this.$http.put('movie/add', {
        movieId: this.id,
        movieQr: this.addForm.url,
        movieCode: this.addForm.code
      })
      if (res.data.code !== '20000') {
        this.$message({
          showClose: true,
          message: res.data.message,
          type: 'error'
        })
        return false
      } else {
        this.visible = false
        this.$message({
          showClose: true,
          message: res.data.message,
          type: 'success'
        })
        this.getGoodList()
      }
    },
    filterTag(value, row) {
      // console.log(value)
      // console.log(row)
      // eslint-disable-next-line eqeqeq
      return row.payStatus == value
    },
    // 监听开关状态的改变
    async usersChanged(userInfo) {
      const { data: res } = await this.$http.put(
        `movies/${userInfo.movieId}/state/${userInfo.isExpire}`
      )
      if (res.code !== '20000') {
        userInfo.status = !userInfo.status
        return this.$message.error('更新状态失败!')
      }
      this.$message.success('更新状态成功！')
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('movies', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取评价列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    handleSelectionChange(val) {},
    addCoupon() {}
  },
  mounted() {},
  created() {
    this.getGoodList()
  }
}
</script>

<style lang="less" scoped>
.el-form-item__content {
  margin-left: 20px !important;
}
.content {
  width: 100%;
}
.el-form {
  margin: 50px 20%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-plus {
  border: 1px dashed #d9d9d9 !important;
  margin-bottom: 10px !important;
  margin-left: 70px !important;
}
</style>
